import { defineStore } from "pinia";
import { useToasterAlert } from "./ToasterAlert";
import axios from "axios";

export const useAdminDashboard = defineStore("AdminDashboard", {

  state: () => ({
    showTableLoader: true,
    currentPlan:{},
    selectedPlan:{},
    currentAddOnServices:[],
    selectedAddOnServices:[],
    users:[],
    selectedAddress:[],
    otherServices:[],
    selectedOtherServices:[],
    monthlyPayment:0,
    yearlyPayment:0,
  }),

  getters: {
    getUserEmail(state){
      return state.email;
    },
    getCurrentPlan(state){
      return state.currentPlan;
    },
    getSelectedPlan(state){
      return state.selectedPlan;
    },
    getCurrentAddOnServices(state){
      return state.currentAddOnServices;
    },
    getOtherServices(state){
      return state.otherServices;
    },
    isSelected: (state) => (service) => {
      return state.selectedAddOnServices.includes(service);
    },
    getSelectedAddOnServices(state){
      return state.selectedAddOnServices;
    },
    getOtherSelectedServices(state){
      return state.selectedOtherServices;
    },
    getOtherSelected: (state) => (service) => {
      return state.selectedOtherServices.includes(service);
    },
    getTotalMonthlyPayment(state){
      return state.monthlyPayment;
    },
    getTotalYearlyPayment(state){
      return state.yearlyPayment;
    },
  },

  actions: {
    async setEmailAndUsers(email,users){
      try{
        console.log("check email and users:",email,users);
        this.email = email;
        this.users = users;
      }catch (error) {
        console.log("error:",error);
      }
    },

    async setLastYearPlan(){
      this.selectedPlan = this.currentPlan;
      this.monthlyPayment = (this.currentPlan?.cost/9).toFixed(0);
      this.yearlyPayment = this.currentPlan?.cost;
    },

    //plans
    async setCurrentPlan(address){
      try{
        const response = await axios.get(`/find/address?address=${address}`);
        console.log("check the current plan:",response?.data[0]);
        this.currentPlan = response?.data[0];
        this.selectedPlan = response?.data[0];
        this.monthlyPayment = (response?.data[0].cost/9).toFixed(0);
        this.yearlyPayment = response?.data[0].cost;
        this.currentAddOnServices = response?.data[0]?.addOnServices;
      }catch (error) {
        console.log("error:",error);
      }
    },
     setSelectedPlan(plan){
      try{
        this.selectedPlan = plan;
        this.monthlyPayment = 0;
        this.yearlyPayment = 0;
        this.monthlyPayment = (plan?.cost/9).toFixed(0);
        this.yearlyPayment = plan?.cost;
        this.currentAddOnServices = [];
      }catch (error) {
        console.log("error:",error);
      }
    },

    // Updated toggleServiceSelection action
    toggleServiceSelection(service) {
      const index = this.selectedAddOnServices.indexOf(service);
      if (index === -1) {
        this.addService(service);
      } else {
        this.removeService(service);
      }
    },

    // Updated addService action
    addService(service) {
      console.log("add service data",service);
      this.yearlyPayment = Number(this.yearlyPayment) + Number(service.cost);
      const monthlyCost = (service.cost/9).toFixed(0)
      this.monthlyPayment = Number(this.monthlyPayment) + Number(monthlyCost);
      console.log("check monthly:",this.monthlyPayment,"yearly:",this.yearlyPayment);
      this.selectedAddOnServices.push(service);
    },

    // Updated removeService action
    removeService(service) {
      console.log("remove service data",service);
      this.yearlyPayment = Number(this.yearlyPayment) - Number(service.cost);
      const monthlyCost = (service.cost/9).toFixed(0)

      this.monthlyPayment = Number(this.monthlyPayment) - Number(monthlyCost);
      this.selectedAddOnServices = this.selectedAddOnServices.filter((s) => s !== service);
    },

    //other services
    async otherServicesData(){
      try{
        const response = await axios.get(`/other/services`);
        console.log("checking the data of response:",response);
        this.otherServices = response.data;
      }catch (error) {
        console.log("error:",error);
      }
    },

    toggleOtherServiceSelection(service) {
      const index = this.selectedOtherServices.indexOf(service);
      if (index === -1) {
        this.selectedOtherServices.push(service);
      } else {
        this.selectedOtherServices.splice(index, 1);
      }
    },

  },
});
