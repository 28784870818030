import { createRouter, createWebHashHistory } from "vue-router";
// import Login from '../views/Login.vue'
import TokenService from "../services/token.service";

const routes = [
  {
    path: "/",
    name: "Root",
    redirect: "/" +
        "user/check-email",
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/navbar",
    name: "navbar",
    component: () =>
      import(/* webpackChunkName: "about" */ "../NewUi/components/Common/Navbar/NewHeaderBar.vue"),
  },
  {
    path: "/user/",
    name: "Admin View",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Admin/Index.vue"),
    // meta: { requiresAuth: true },
    children: [
      {
        path: "check-email",
        name: "CheckEmail",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../NewUi/components/views/Admin/CheckEmail.vue"
          ),
        // meta: { requiresAuth: true },
      },
      {
        path: "select-plan",
        name: "SelectPlan",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../NewUi/components/views/Admin/SelectPlan.vue"
          ),
        // meta: { requiresAuth: true },
      },
      {
        path: "add-on-services",
        name: "AddOnServices",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../NewUi/components/views/Admin/AddOnServices.vue"
          ),
        // meta: { requiresAuth: true },
      },
      {
        path: "other-services",
        name: "OtherServices",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../NewUi/components/views/Admin/OtherServices.vue"
          ),
        // meta: { requiresAuth: true },
      },
      {
        path: "finalize",
        name: "Finalize",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../NewUi/components/views/Admin/Finalize.vue"
          ),
        // meta: { requiresAuth: true },
      },
      {
        path: "thank-you",
        name: "ThankYou",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../NewUi/components/views/Admin/ThankYouMessage"
          ),
        // meta: { requiresAuth: true },
      },
    ],
  },
];


// Set a flag in local storage to check if it's a page refresh
function setPageRefreshFlag() {
  localStorage.setItem("isPageRefresh", "true");
}

// Clear the page refresh flag from local storage
function clearPageRefreshFlag() {
  localStorage.removeItem("isPageRefresh");
}

// Check if the page is being refreshed
function isPageRefresh() {
  return localStorage.getItem("isPageRefresh") === "true";
}


const router = createRouter({
  history: createWebHashHistory(),
  // You are going to have to make an ENV File
  // history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach(async (to, from, next) => {
  // Check if it's a page refresh
  if (isPageRefresh()) {
    // Clear the refresh flag
    clearPageRefreshFlag();
    // Redirect to the main page or any desired route
    next("/");
  } else {
    // Continue with normal navigation
    next();
  }
});
// Set the page refresh flag when the page is refreshed
window.addEventListener("beforeunload", setPageRefreshFlag);


export default router;
